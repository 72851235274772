<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
    </div>
    <div>
      <!-- Filter Inputs -->

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <strong>Filter Options</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CInput v-model="brandFilter" placeholder="Filter by Brand" />
                </CCol>
                <CCol>
                  <CInput v-model="modelFilter" placeholder="Filter by Model" />
                </CCol>
                <CCol>
                  <CInput
                    v-model="statusFilter"
                    placeholder="Filter by Status"
                  />
                </CCol>
              </CRow>

              <!-- Row for Button -->
              <CRow class="d-flex justify-content-end mt-3">
                <CCol sm="auto">
                  <CButton color="primary" class="ml-auto" @click="refreshTable"
                    >Apply Filters</CButton
                  >
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Stock </strong> List </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="statusColumnFilteredItems"
                :fields="fields"
                column-filter
                items-per-page-select
                :items-per-page="100"
                hover
                sorter
                pagination
                :loading="loading"
              >
                <template #show_index="{ index }">
                  <td class="py-2">
                    {{ index + 1 }}
                  </td>
                </template>

                <template #latest_stock_status-filter="{ item }">
                  <CInput v-model="statusColumnFilter" size="sm" class="m0" />
                </template>

                <template #latest_stock_status="{ item }">
                  <td>
                    <CBadge :color="getBadge(item.latestStatus)">{{
                      item.latestStatus
                    }}</CBadge>
                  </td>
                </template>
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template>
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <p class="text-muted">Stock No: {{ item.stockNo }}</p>
                      <p class="text-muted">
                        Status: {{ getLatesStockStatusName(item) }}
                      </p>
                      <p class="text-muted">Brand: {{ item.brandName }}</p>
                      <p class="text-muted">Model: {{ item.modelName }}</p>
                      <p class="text-muted">Price: {{ item.price }}</p>
                      <img
                        :src="getImage(item)"
                        alt="Car Image"
                        v-if="getImage(item)"
                        style="max-width: 30%; height: auto; margin-top: 10px"
                      />
                      <div class="button-group">
                        <CButton
                          size="sm"
                          color="info"
                          class="ml-1"
                          @click="onEdit(item)"
                        >
                          Edit
                        </CButton>
                        <CButton
                          size="sm"
                          color="danger"
                          class="ml-1"
                          @click="showDeleteConfirmation(item)"
                        >
                          Delete
                        </CButton>
                      </div>
                    </CCardBody>
                  </CCollapse>
                </template>
              </CDataTable>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                class="ml-1"
                color="primary"
                @click="addNew"
              >
                Add New
              </CButton>
              <CButton
                type="submit"
                class="ml-1"
                color="primary"
                :href="csvCode"
                download="stocks.csv"
                target="_blank"
              >
                Download
              </CButton>
              <!--               
              <CButton class="ml-1" color="primary" @click="downloadCSV"
                >Download CSV</CButton
              > -->
            </CCardFooter>
          </CCard>
          <CModal
            title="Confirm Delete"
            color="warning"
            :show.sync="warningModal"
            @update:show="onDeleteConfirmation"
          >
            Are you sure you want to delete this {{ itemToDelete.fullName }} ?
          </CModal>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import StockApi from "../../lib/stockApi";

const items = [];
const fields = [
  {
    key: "show_index",
    label: "#",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },

  { key: "stockNo" },
  // { key: "latestStatus" },
  {
    key: "latest_stock_status",
    _style: "min-width:100px;",
    label: "Status",
    filter: false,
  },
  { key: "brandName" },
  { key: "modelName" },
  { key: "price" },

  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "StockList",
  data() {
    return {
      // Filter states
      brandFilter: "",
      modelFilter: "",
      statusFilter: "",

      statusColumnFilter: "", // New data property for filtering by status
      loading: true,
      items: items.map((item, id) => {
        return { ...item, id };
      }),
      infoList: [],
      fields,
      details: [],
      collapseDuration: 0,
      api: new StockApi(),
      warningModal: false,
      itemToDelete: {},
    };
  },
  mounted() {
    var self = this;
    self.refreshTable();
  },
  computed: {
    computedCsvContent() {
      let index = 1;
      return this.statusColumnFilteredItems.map((item) => {
        return {
          index: index++,
          latestStatus: item.latestStatus,
          brandName: item.brandName,
          modelName: item.modelName,
          price: item.price
        };
      });
    },
    computedCsvContentWithHeader() {
      const csvRows = [];

      // Add headers
      csvRows.push(["No", "Latest Status", "Brand", "Model", "Price (RM)"].join(","));

      // Add rows
      csvRows.push(...this.computedCsvContent.map(item => {
        return [
          item.index,
          item.latestStatus,
          item.brandName,
          item.modelName,
          item.price
        ].map(field => `"${String(field).replace(/"/g, '""')}"`).join(","); // Escape quotes and format CSV
      }));

      return csvRows.join("\r\n");
    },
    csvCode() {
      const BOM = '\uFEFF'; // UTF-8 BOM
      return "data:text/csv;charset=utf-8," + encodeURIComponent(BOM + this.computedCsvContentWithHeader);
    },
    statusColumnFilteredItems() {
      if (this.statusColumnFilter) {
        return this.computedItems.filter((item) => {
          return item.latestStatus
            .toLowerCase()
            .includes(this.statusColumnFilter.toLowerCase());
        });
      } else {
        return this.computedItems;
      }
    },
    computedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          latestStatus: this.getLatesStockStatusName(item),
          brandName: this.getBrandName(item),
          modelName: this.getModelName(item),
          price: this.getPricing(item),
        };
      });
    },
  },
  methods: {
    // downloadCSV() {
    //   const csvData = this.convertToCSV(this.statusColumnFilteredItems);
    //   const blob = new Blob([csvData], { type: "text/csv" });
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "stock_data.csv";
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // },
    // convertToCSV(data) {
    //   const csvRows = [];

    //   // Get the headers
    //   const headers = Object.keys(data[0]);
    //   csvRows.push(headers.join(","));

    //   // Loop through each item and create a row
    //   for (const row of data) {
    //     const values = headers.map((header) => {
    //       const escaped = ("" + row[header]).replace(/"/g, '\\"'); // Escape quotes
    //       return `"${escaped}"`; // Wrap in double quotes
    //     });
    //     csvRows.push(values.join(","));
    //   }

    //   return csvRows.join("\n");
    // },

    // filterStatus(filterText) {
    //   console.log(filterText);
    //   // Custom logic to filter based on the input
    //   // this.fields.find(f => f.key === key).filterFunction = (value) => {
    //   //   return !filterText || value.toLowerCase().includes(filterText.toLowerCase());
    //   // };
    // },
    getImage(item) {
      if (item.vehicle == null) return "";
      if (item.vehicle.vehiclePhotoList == null) return "";
      if (item.vehicle.vehiclePhotoList.length == 0) return "";
      var url = "";
      url =
        apiUrl +
        "Documents/File/" +
        item.vehicle.vehiclePhotoList[0].documentId;
      return url;
    },
    getPricing(item) {
      if (item.pricing == null) return "N/A";
      if (item.pricing.recommendedSalePrice == 0) return "N/A";
      return new Intl.NumberFormat("ms-MY", {
        style: "currency",
        currency: "MYR",
      }).format(item.pricing.recommendedSalePrice);
    },
    getBrandName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.brand == null) return "N/A";
      return item.vehicle.brand.name;
    },
    getModelName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.model == null) return "N/A";
      return item.vehicle.model.name;
    },
    getLatesStockStatusName(item) {
      if (item.latestStockStatus == null) return "N/A";
      if (item.latestStockStatus.stockStatus == null) return "N/A";
      return item.latestStockStatus.stockStatus.name;
    },
    getBadge(status) {
      const statusColors = {
        Draft: "light",
        Registered: "primary",
        LOU: "primary",
        Shipped: "primary",
        ArriveAtPort: "primary",
        ShowRoom: "warning",
        Available: "warning",
        AVAILABLE: "warning",
        DUTY: "danger",
        "INCOMING STOCK": "light",
        Booked: "success",
        BOOKING: "success",
        Cancelled: "dark",
        Sold: "success",
      };

      return statusColors[status] || ""; // Return the color if found, otherwise return an empty string
    },
    // getBadge(status) {
    //   return status === "Draft"
    //     ? "primary"
    //     : status === "Registered"
    //     ? "secondary"
    //     : status === "Shipped"
    //     ? "warning"
    //     : status === "ArriveAtPort"
    //     ? "success"
    //     : status === "ShowRoom"
    //     ? "info"
    //     : status === "Available"
    //     ? "danger"
    //     : status === "Booked"
    //     ? "light"
    //     : status === "Cancelled"
    //     ? "dark"
    //     : "";
    // },

    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    refreshTable() {
      var self = this;
      const filters = {
        brand: this.brandFilter,
        model: this.modelFilter,
        status: this.statusFilter,
      };

      self.loading = true;
      self.api
        .getListByFilter(filters)
        .then((response) => {
          self.items = response.result;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
          self.loading = false;
        });
    },
    onEdit(item) {
      var self = this;
      self.$router.push({
        path: `/admins/Stock/${item.id}`,
      });
    },
    onDeleteConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .delete(self.itemToDelete.id)
          .then((response) => {
            self.refreshTable();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemToDelete = {};
    },
    showDeleteConfirmation(item) {
      var self = this;
      self.itemToDelete = item;
      self.warningModal = true;
    },
    addNew() {
      this.$router.push({ path: "/admins/Stock" });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  },
};
</script>

<style scoped>
.button-group {
  margin-top: 10px;
}
</style>
